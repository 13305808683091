import { graphql } from 'gatsby';
import RegulationsPage from '../views/legal/regulations';

export default RegulationsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    regulationsObjects: allContentfulRegulations {
      edges {
        node {
          language
          content {
            raw
          }
          node_locale
        }
      }
    }
  }
`;
