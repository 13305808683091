import * as React from 'react';
import Layout from '../../components/layout/layout';
import './style/legal.css';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import getProperLangDocument from './components/proper-lang';
import Seo from '../../components/seo';
import getCurrentTranslations from '../../components/contentful-translator';
import ogImage from '../../images/seo/SEO_MBIP.jpg';

const RegulationsPage = ({ data }) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const regulationsObjects = getCurrentTranslations(
    data.regulationsObjects.edges,
    language
  );
  const properRegulations = getProperLangDocument(regulationsObjects, language);

  return (
    <Layout>
      <Seo
        title={t('regulations.page-title')}
        description={t('regulations.page-description')}
        ogImage={ogImage}
      />
      <section className="page-section container legal" id="regulations">
        {renderRichText(properRegulations)}
      </section>
    </Layout>
  );
};

export default RegulationsPage;
